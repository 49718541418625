@import 'text', 'variables', 'breakpoints';

.white-box {
  display: block;
  padding: 1.875rem 0.625rem;
  background-color: white;
  border-radius: 1.25rem;

  &__title {
    @extend %text-normal-semibold;

    display: flex;
    align-items: center;
    margin: 0 0 1.375rem;
    color: $theme-primary;

    &__icon {
      margin-right: 0.375rem;
    }

    &--not-first {
      margin-bottom: 0;
    }
  }

  &__text {
    @extend %text-normal;

    margin: 0;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 1.875rem;
    position: relative;
  }

  @include notHandset {
    padding: 1.875rem;
  }
}
