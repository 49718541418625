// Angular material
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '@ng-select/ng-select/themes/material.theme.css';

// Add normalize
@import 'normalize.css';

// Flag Icons
@import 'flag-icons/css/flag-icons.css';
@import 'utils', 'themes', 'override/material', 'override/wizbii/wzb-toggle', 'override/wizbii/wzb-linear-navbar',
  'override/widgets/ngselect', 'override/widgets/ck-editor', 'variables', 'button', 'breakpoints', 'heading', 'text',
  'loader', 'gdpr', 'safe-area', 'white-box', 'form', 'badge', 'override/editorjs/editorjs';

html {
  overflow: visible !important;
  scroll-behavior: smooth;
  font-family: $font-family-sans-serif;
  line-height: 1.625;
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  padding-right: 0 !important;
  overflow: visible scroll !important; // setting `overflow-y` has the unwanted effect of setting `overflow-x` to `auto`... // prevent jumps when switching between long and short pages
  background-color: $theme-bg;
  color: $wizbii-black;
}

textarea {
  font-family: inherit;
  resize: vertical;
}

pre {
  overflow: auto;
}

label,
output {
  display: inline-block;
}

img,
svg {
  vertical-align: middle;
}

svg {
  display: block;
  border: 0;
  fill: currentcolor;

  &:not(:root) {
    overflow: hidden;
  }
}

a {
  color: inherit;
  text-decoration-skip-ink: auto;
  text-decoration: none;

  &:not([href]) {
    text-decoration: none !important;
  }
}

button,
input,
optgroup,
select,
textarea {
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}

input {
  // stylelint-disable-next-line selector-no-qualifying-type
  &[type='number'] {
    appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
}

button,
summary {
  cursor: pointer;
}

strong {
  font-weight: 600;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

[hidden] {
  display: none !important;
}

[tabindex='-1']:focus {
  outline: 0 !important;
}

@media (prefers-reduced-motion) {
  * {
    transition: none !important;
    animation: none !important;
  }
}

.lock {
  overflow: hidden;
}

.hover-on {
  a:hover {
    text-decoration: underline;
  }
}

.capitalize {
  text-transform: capitalize;
}
