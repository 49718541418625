@use 'sass:color';
@import 'variables', 'text', 'breakpoints';

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: $theme-primary;
  border: none;
  border-radius: 0.625rem;
  color: white;
  padding: 0.625rem 1.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  outline-offset: 1px;
  outline-color: $theme-primary;
  box-shadow: none;
  transition:
    opacity 0.15s ease-out,
    transform 0.15s ease-out,
    box-shadow 0.15s ease-out,
    background-color 0.15s ease-out,
    color 0.15s ease-out;
  cursor: pointer;
  position: relative;
  opacity: 1;

  &--outline {
    background-color: white;
    color: $theme-primary;
    box-shadow: inset 0 0 0 0.0625rem $theme-primary;
  }

  &--light {
    background-color: $suggestii-light-bg;
    color: $suggestii-primary;

    &:hover {
      background-color: $suggestii-primary;
      color: white;
      box-shadow: none;
    }
  }

  &--danger {
    background-color: $invalid-red;
    border-color: $invalid-red;
    color: white;

    &--outline {
      background-color: white;
      color: $invalid-red;
      border-color: $invalid-red;
      box-shadow: inset 0 0 0 0.0625rem $invalid-red;

      &:focus {
        border-color: $invalid-red;
      }
    }

    &:hover {
      background-color: $invalid-red;
      border-color: $invalid-red;
      color: white;
    }
  }

  &--icon {
    svg-icon {
      font-size: 1rem;
      margin-left: 0.3125rem;
    }
  }

  &--loading:disabled {
    opacity: 1;
  }

  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: color.adjust($theme-secondary, $alpha: -0.9);
    transform: scaleX(0.8);
    transform-origin: 0;
    animation: loader-anim 1s ease-out;
    z-index: 10;
  }

  @keyframes loader-anim {
    0% {
      transform: scaleX(0.1);
    }
    30% {
      transform: scaleX(0.3);
    }
    80% {
      transform: scaleX(0.8);
    }
  }

  &:disabled, // use attribute `disabled` for buttons
  &--disabled {
    // use class `btn--disabled` with `tabindex="-1"` for links
    opacity: 0.3;
    pointer-events: none;
    box-shadow: none;
  }

  &:active {
    transform: scale(0.99);
    box-shadow: 0 0.625rem 1.25rem rgb(0 0 0 / 0%);
  }

  &--google,
  &--facebook {
    @extend %text-normal;

    padding: 0.75rem 1.25rem;
    min-width: 8.125rem;
    font-weight: normal;
    color: white;

    svg-icon {
      margin-right: 0.875rem;
    }
  }

  &--facebook {
    background: #4a74b8;
    outline-color: #4a74b8;
  }

  &--google {
    background: #f25d51;
    outline-color: #f25d51;
  }
}

.hover-on {
  .btn:hover {
    text-decoration: none;
    transform: scale(1.01);
    box-shadow: 0 0.325rem 1.25rem rgb(0 0 0 / 15%);
  }

  .btn--outline:hover {
    background-color: $theme-primary;
    color: white;
  }
}

.btn-clean {
  display: inline-block;
  padding: 0;
  background: transparent;
  border: none;
  color: inherit;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  appearance: none;
}

.btn-square {
  padding: 0.625rem;
  border-radius: 0.625rem;
  border: 1px solid $theme-secondary;
  line-height: 1;

  &:disabled, // use attribute `disabled` for buttons
  &--disabled {
    // use class `btn--disabled` with `tabindex="-1"` for links
    opacity: 0.3;
    pointer-events: none;
  }
}

.modes {
  display: flex;
  position: relative;
  z-index: 0;
  min-width: none;
  border-radius: 0.625rem;
  background-color: white;
  width: 100%;
  margin-bottom: 2.5rem;

  &__btn {
    @extend %text-small-semibold;

    box-sizing: border-box;
    position: relative;
    z-index: 2;
    padding: 1rem 1.25rem;
    width: 50%;
    transition:
      background-color 0.15s ease-out,
      transform 0.15s ease-out,
      box-shadow 0.15s ease-out,
      color 0.15s ease-out;

    &:not(.active) {
      &:hover {
        background-color: #e3f8ff;
        border-radius: 0.625rem;
      }
    }

    &__locked {
      position: absolute;
      top: 1.5625rem;
      right: 0.5625rem;
      font-size: 0.75rem;
      color: $invalid-red;

      @include notHandset {
        top: 1.5625rem;
        right: 0.25rem;
      }
    }
  }

  &::before {
    content: '';
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    background-color: $theme-primary;
    transition: left 0.15s ease-out;
    border-radius: 0.625rem;
    box-shadow: 0 0.625rem 1.25rem rgb(0 0 0 / 15%);
  }

  &[position='left'] {
    &::before {
      left: 0;
    }
  }

  &[position='right'] {
    &::before {
      left: 50%;
    }
  }

  @include notHandset {
    margin-bottom: 1.25rem;
    width: auto;
    max-width: 23.125rem;
  }
}
