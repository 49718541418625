@import 'variables', 'text', 'breakpoints';

// stylelint-disable selector-max-specificity, selector-no-qualifying-type, selector-type-no-unknown, no-descending-specificity

.mat-tab-nav-bar {
  padding: 0 1.25rem;
  overflow: scroll !important;
  border-bottom: none;
  -ms-overflow-style: none;

  .mat-tab-link-container {
    overflow-x: auto;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &:not([arrow-nav='true']) {
    .mat-tab-header-pagination {
      display: none !important;
    }
  }

  .mat-tab-link {
    @extend %text-medium;

    height: 1.875rem;
    text-align: left;
    padding: 0 2.75rem 0 0.625rem;
    justify-content: flex-start;
    color: $grey-6;
    opacity: 1;
    transition: color 0.15s ease-out;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      background-color: $theme-primary;
      border-radius: 0.25rem;
      height: 100%;
      width: 0.125rem;
      opacity: 0;
      transition: opacity 0.5s ease-out;
    }

    &.mat-tab-label-active {
      @extend %text-large-semibold;

      color: $wizbii-black;

      &::before {
        opacity: 1;
      }
    }

    &:hover {
      text-decoration: none;
    }

    &:not(.mat-tab-label-active):hover {
      color: $wizbii-black;
    }
  }

  .mat-ink-bar {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .mat-tab-links {
    overflow: initial !important;
  }

  @include notHandset {
    padding: 0;
  }
}

.mat-tab-group {
  padding: 0 1.25rem;

  .mat-tab-header {
    overflow: scroll !important;
    border-bottom: none;
  }

  .mat-tab-link-container {
    overflow-x: auto;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &:not([arrow-nav='true']) {
    .mat-tab-header-pagination {
      display: none !important;
    }
  }

  .mat-tab-label {
    @extend %text-medium;

    height: 1.875rem;
    min-width: fit-content;
    text-align: left;
    padding: 0 2.75rem 0 0.625rem;
    justify-content: flex-start;
    color: $grey-6;
    opacity: 1;
    transition: color 0.15s ease-out;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      background-color: $theme-primary;
      border-radius: 0.25rem;
      height: 100%;
      width: 0.125rem;
      opacity: 0;
      transition: opacity 0.5s ease-out;
    }

    &.mat-tab-label-active {
      @extend %text-large-semibold;

      color: $wizbii-black;

      &::before {
        opacity: 1;
      }
    }

    &:hover {
      text-decoration: none;
    }

    &:not(.mat-tab-label-active):hover {
      color: $wizbii-black;
    }
  }

  .mat-ink-bar {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .mat-tab-links {
    overflow: initial !important;
  }

  @include notHandset {
    padding: 0;
  }
}
