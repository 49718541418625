@import 'variables';

.ng-select {
  margin-top: -0.45rem;
}

.ng-select .ng-select-container.ng-appearance-outline::after {
  border-radius: 0.625rem;
  border-width: 0.062rem !important;
  box-sizing: border-box;
}

.ng-select .ng-select-container.ng-appearance-outline {
  min-height: 4rem;
  box-sizing: border-box;
}

// stylelint-disable selector-max-specificity, selector-no-qualifying-type, selector-type-no-unknown, no-descending-specificity
.ng-select .ng-select-container .ng-value-container .ng-input {
  bottom: 0.9em;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  padding: 0 0.65rem;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  line-height: 1.25;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.ng-select .ng-select-container.ng-appearance-outline .ng-value {
  padding-top: 0.25rem;
}

.ng-dropdown-panel.ng-select-bottom {
  border-radius: 0.625rem;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  color: $suggestii-primary;
  background-color: $suggestii-light-bg;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: $suggestii-light-bg;
  border-radius: 0.25rem;
  color: $suggestii-primary;
  padding: 0.1875rem 0.5625rem;

  & .ng-value-icon {
    color: $suggestii-primary;
    opacity: 0.7;
    &:hover {
      color: $suggestii-primary;
      opacity: 1;
    }
  }
}
