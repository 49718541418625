@use 'variables' as vars;

.ck-editor__editable_inline {
  min-height: 18.75rem !important;
  border-radius: 1.25rem !important;
  border: 0.0625rem solid #e6e7f3 !important;
  padding: 1rem 2rem !important;
  margin-top: 0.5rem !important;
}

.ck-focused {
  border: 1px solid vars.$wizbii-purple !important;
  box-shadow: none !important;
}

.ck-toolbar {
  border-radius: 1.25rem !important;
  border: 0.0625rem solid #e6e7f3 !important;
  padding: 0 2rem !important;
}

.ck-toolbar_vertical {
  border-radius: 0 !important;
  padding: 0 !important;
}

.ck-list-styles-dropdown > .ck-dropdown__panel > .ck-toolbar {
  border-radius: 0 !important;
  padding: 0 !important;
}

.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_sw {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
