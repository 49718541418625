@import 'variables';

// stylelint-disable selector-max-specificity, selector-no-qualifying-type, selector-type-no-unknown, no-descending-specificity, max-nesting-depth
.mat-select-panel-wrap {
  ::-webkit-scrollbar {
    width: 0.375rem;
    background: none;
  }

  ::-webkit-scrollbar-thumb {
    transition: background-color 0.15s;
    background-color: rgb(194 194 194);
    border-radius: 0.625rem;

    &:hover {
      background-color: rgb(125 125 125);
    }
  }

  .constraint-panel {
    .mat-option {
      line-height: unset;
      min-height: 3em;
      height: auto;
      padding-top: 0.625rem;
      padding-bottom: 0.625rem;

      .dependency {
        &__header {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.2rem;

          &__title {
            font-weight: 500;
          }

          &__type {
            color: $theme-primary;
            text-transform: uppercase;
          }
        }

        &__body {
          font-size: 0.85rem;
          opacity: 0.8;
          font-style: italic;
        }
      }
    }
  }

  .drive-panel {
    &.mat-select-panel {
      background-color: white;
      box-shadow: 0 0.5625rem 1.875rem 0 rgb(0 0 0 / 13%);
      margin-top: 2.1875rem;
      border-radius: 0.625rem;
    }

    .mat-option {
      transition:
        color 0.15s,
        background-color 0.15s;
      color: $wizbii-black;

      &:hover:not(.mat-option-disabled),
      &.mat-active {
        background-color: #f6f8fb;
        color: $theme-primary;
      }

      &.mat-option-disabled {
        opacity: 0.5 !important;
      }
    }
  }
}

.hover-on {
  .mat-select-panel-wrap::-webkit-scrollbar-thumb:hover {
    background-color: rgb(125 125 125);

    .drive-panel {
      .mat-option:hover:not(.mat-option-disabled) {
        background-color: #f6f8fb;
        color: $theme-primary;
      }
    }
  }
}

.mat-select--white {
  .mat-form-field-outline {
    box-shadow: 0 0.625rem 1.25rem rgb(0 0 0 / 15%);
    background-color: white !important;

    .mat-form-field-outline-end,
    .mat-form-field-outline-start {
      border: none;
    }
  }

  .mat-select-trigger {
    .mat-select-value {
      font-weight: 600;
      font-size: 0.875rem;
    }
  }
}

.mat-select-panel .choice-option {
  line-height: inherit !important;
  height: 4.5em !important;
}
