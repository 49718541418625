%text-very-small {
  font-style: normal;
  font-weight: normal;
  font-size: 0.8125rem;
  line-height: 1.231;
}

@mixin text-very-small() {
  font-style: normal;
  font-weight: normal;
  font-size: 0.8125rem;
  line-height: 1.231;
}

%text-very-small-semibold {
  @extend %text-very-small;

  font-weight: 600;
  line-height: 1.24;
}

@mixin text-very-small-semibold() {
  @include text-very-small;

  font-weight: 600;
  line-height: 1.24;
}

%text-small {
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.429;
}

@mixin text-small() {
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.429;
}

%text-small-semibold {
  @extend %text-small;

  font-weight: 600;
  line-height: 1.375;
}

@mixin text-small-semibold() {
  @include text-small;

  font-weight: 600;
  line-height: 1.375;
}

%text-normal {
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.625;
}

@mixin text-normal() {
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.625;
}

%text-normal-semibold {
  @extend %text-normal;

  font-weight: 600;
  line-height: 1.375;
}

@mixin text-normal-semibold() {
  @include text-normal;

  font-weight: 600;
  line-height: 1.375;
}

%text-medium {
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.5;
}

@mixin text-medium() {
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.5;
}

%text-medium-semibold {
  @extend %text-medium;

  font-weight: 600;
}

@mixin text-medium-semibold() {
  @include text-medium;

  font-weight: 600;
}

%text-large {
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.5;
}

@mixin text-large() {
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.5;
}

%text-large-semibold {
  @extend %text-large;

  font-weight: 600;
}

@mixin text-large-semibold() {
  @include text-large;

  font-weight: 600;
}
