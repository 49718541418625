@use 'sass:math';
@use 'sass:map';

@function px-to-em($val) {
  @return math.div($val, 16px) * 1em;
}

// stylelint-disable unit-disallowed-list
$sm: px-to-em(480px);
$sm-max: px-to-em(480px - 1);
$md: px-to-em(768px);
$md-max: px-to-em(768px - 1);
$lg: px-to-em(992px);
$lg-max: px-to-em(992px - 1);
$xl: px-to-em(1200px);
$xl-max: calc(1200px - 0.0625em);
$xxl: px-to-em(1440px);
$breakpoints: (
  sm: $sm,
  md: $md,
  lg: $lg,
  xl: $xl,
  xxl: $xxl,
);
$breakpointsMax: (
  sm-max: $sm-max,
  md-max: $md-max,
  lg-max: $lg-max,
  xl-max: $xl-max,
);

@mixin minWidth($key) {
  @media (min-width: map.get($breakpoints, $key)) {
    @content;
  }
}

@mixin maxWidth($key) {
  @media (max-width: map.get($breakpointsMax, $key)) {
    @content;
  }
}

@mixin minWidthLandscape($key) {
  @media (min-width: map.get($breakpoints, $key)) and (orientation: landscape) {
    @content;
  }
}

@mixin maxWidthLandscape($key) {
  @media (max-width: map.get($breakpointsMax, $key)) and (orientation: landscape) {
    @content;
  }
}

@mixin handset() {
  @media (width <= 599.99px) and (orientation: portrait), (width <= 959.99px) and (orientation: landscape) {
    @content;
  }
}

@mixin notHandset() {
  @media (width >= 600px) and (width <= 839.99px) and (orientation: portrait),
    (width >= 960px) and (width <= 1279.99px) and (orientation: landscape),
    (width >= 840px) and (orientation: portrait),
    (width >= 1280px) and (orientation: landscape) {
    @content;
  }
}

@mixin tablet() {
  @media (width >= 600px) and (width <= 839.99px) and (orientation: portrait),
    (width >= 960px) and (width <= 1279.99px) and (orientation: landscape) {
    @content;
  }
}

@mixin tabletPortrait() {
  @media (width >= 600px) and (width <= 839.99px) and (orientation: portrait) {
    @content;
  }
}

@mixin tabletLandscape() {
  @media (width >= 960px) and (width <= 1279.99px) and (orientation: landscape) {
    @content;
  }
}

@mixin web() {
  @media (width >= 840px) and (orientation: portrait), (width >= 1280px) and (orientation: landscape) {
    @content;
  }
}

@mixin largeTabletPortrait() {
  @media (width >= 1024px) and (width <= 1366px) and (orientation: portrait) and (resolution >= 2dppx) {
    @content;
  }
}
