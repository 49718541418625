.codex-editor {
  & svg {
    fill: none !important;
  }
  &__redactor {
    padding: 1.875rem 0 6.6875rem !important;
  }
}

.ce-block__content {
  max-width: unset;
  margin-left: 3.75rem;
  margin-right: 3.75rem;
}

.ce-toolbar__actions {
  right: unset;
  padding-left: 0.3125rem;
}

.ce-toolbar__content {
  max-width: unset;
}
