@import 'heading', 'text';

.wizbii-panel,
.wizbii-panel--white {
  .mat-dialog-container {
    position: relative;
    padding: 1.25rem;
    background-color: white;
    border-radius: 1.25rem;
    box-shadow: 0 0.9375rem 1.875rem rgb(0 0 0 / 15%);
  }

  .mat-dialog-title {
    @extend %heading-4;

    text-align: center;
  }

  .mat-dialog-content {
    @extend %text-normal;

    padding: 1.25rem;
    margin: 0;
    background-color: #f9fbfe;
    border-radius: 1.25rem;
  }

  .mat-dialog-actions {
    margin: 0;
    padding: 1.25rem 0 0;
    text-align: center;
  }

  .btn:hover {
    text-decoration: none;
    transform: scale(1.01);
    box-shadow: 0 0.625rem 1.25rem rgb(0 0 0 / 30%);
  }

  .btn--outline:hover {
    background-color: $theme-primary;
    color: $theme-secondary;
  }
}

.wizbii-panel--white {
  .mat-dialog-content {
    background-color: white;
  }

  .mat-dialog-actions {
    padding: 0;
  }
}
