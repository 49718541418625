@import 'variables';

// https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
@mixin visually-hidden {
  position: absolute !important;
  overflow: hidden;
  // stylelint-disable unit-disallowed-list
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  // stylelint-enable unit-disallowed-list
}

.visually-hidden {
  @include visually-hidden;
}

.error {
  position: absolute;
  right: 0;
  bottom: 0;
  color: $invalid-red;
  font-size: 0.825rem;
  font-weight: 500;
  text-align: center;

  &--form {
    margin: 0;
    color: #f44336;
    font-size: 0.625rem;
    font-weight: 500;
    text-align: center;
  }
}
