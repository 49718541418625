@import 'variables', 'breakpoints';

// stylelint-disable selector-max-specificity, selector-no-qualifying-type, selector-type-no-unknown, no-descending-specificity
.mat-drawer {
  border: none;
  background-color: $theme-primary;
  color: white;
  width: 6.25rem;

  .mat-list-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1em !important;
    border-radius: 0.938rem !important;
    width: 2.75rem !important;
    height: 2.7rem !important;
    transition: background-color 0.15s ease-out;
  }

  .mat-list-item {
    color: white;
    margin: 0.5rem 0;
    font-size: 1rem;

    &.sidenav-active {
      .mat-list-icon {
        background-color: $suggestti-accent-primary;
      }
    }

    &:focus,
    &:hover {
      background: none;
    }
  }

  .mat-drawer-inner-container {
    overflow-x: visible;
  }
}

.mat-drawer-content {
  position: fixed;
  overflow-x: hidden !important;

  @include handset {
    user-select: none !important;
    -webkit-tap-highlight-color: transparent !important;
    -webkit-touch-callout: none !important;
    -webkit-overflow-scrolling: touch !important;
  }
}

.mat-drawer-container {
  position: fixed;

  @include handset {
    user-select: none !important;
    -webkit-tap-highlight-color: transparent !important;
    -webkit-touch-callout: none !important;
    -webkit-overflow-scrolling: touch !important;
  }
}

.fix-ios-bug {
  .mat-drawer-content,
  .mat-drawer-container {
    & > * {
      transform: translateZ(0) !important;
    }
  }
}
