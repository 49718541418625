// stylelint-disable selector-max-specificity, max-nesting-depth
.mat-autocomplete-panel {
  &.constraint-panel {
    .mat-option {
      line-height: unset;
      min-height: 3em;
      height: auto;
      padding-top: 0.625rem;
      padding-bottom: 0.625rem;

      .dependency {
        &__header {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.2rem;

          &__title {
            font-weight: 500;
          }

          &__type {
            color: $theme-primary;
            text-transform: uppercase;
          }
        }

        &__body {
          font-size: 0.85rem;
          opacity: 0.8;
          font-style: italic;
        }
      }
    }
  }
}
