@import 'variables';

// stylelint-disable selector-max-specificity, selector-no-qualifying-type, selector-type-no-unknown, no-descending-specificity
.mat-table {
  .mat-header-row {
    border-radius: 0.625rem;
    background-color: $suggestii-light-bg;
    min-height: 2.4rem !important;
    margin-bottom: 1rem !important;
    border: unset;

    .mat-header-cell {
      font-weight: normal;
      color: $theme-primary;
      font-size: 0.75rem;
      padding-right: 1rem;
    }
  }

  .mat-row {
    border-radius: 0.625rem;
    border: 1px solid #e4e4eb;
    margin-bottom: 0.5rem;
    min-height: 2.7rem;

    .mat-cell {
      font-size: 0.813rem;
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;
      padding-right: 1rem;

      &:last-of-type {
        padding-right: 1.4rem;
      }
    }
  }
}
