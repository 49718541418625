@import 'variables';

.primary-outline-badge {
  border: 1px solid $theme-primary;
  padding: 0.375rem 0.625rem;
  border-radius: 0.5rem;
  font-weight: 500;
  line-height: 1.3;
}

.chip {
  font-size: 0.688rem;
  border-radius: 0.5rem;
  padding: 0.2rem 0.438rem;
}

.chip--success {
  @extend .chip;

  background-color: $valid-bg;
  color: $valid-text;
}

.chip--error {
  @extend .chip;

  background-color: $invalid-bg;
  color: $invalid-text;
}

.chip--big {
  font-size: 0.9rem;
  padding: 0.375rem 0.625rem;
}
