$font-family-sans-serif:
  'Montserrat',
  -apple-system,
  'BlinkMacSystemFont',
  'Segoe UI',
  'Roboto',
  'Helvetica Neue',
  'Arial',
  'Noto Sans',
  sans-serif,
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
  'Noto Color Emoji';

// Wizbii brand colors
$wizbii-purple: #8782ff;
$wizbii-purple-transparent-32: rgb(135 130 255 / 32%);
$wizbii-purple-dark: #0a2456;
$wizbii-blue: #45ceff;
$wizbii-yellow: #f9ed20;
$wizbii-red: #ff5c60;
$wizbii-green: #3df9c6;
$wizbii-pink: #e8a3f4;
$wizbii-black: #000028;
$wizbii-placeholder-grey: #e3e3e3;

// Suggestii colors
$suggestii-primary: #5750fb;
$suggestii-secondary: #000028;
$suggestti-accent-primary: #4841e9;
$suggestii-dark-primary: #2d2e45;
$suggestii-light-bg: rgb(221 220 254 / 50%);

// Alert colors
$valid-green: #3aea98;
$warn-orange: #ffbf16;
$invalid-red: #ff5c61;
$valid-bg: #e3fff1;
$valid-text: #05a660;
$invalid-bg: #ffe7e7;
$invalid-text: #ff5c61;

// Theme
$theme-bg: white;
$theme-primary: $suggestii-primary;
$theme-secondary: $suggestii-secondary;

// Third-party brand colors
$google-red: #f45c4c;
$facebook-blue: #4872ba;

// Others colors
$grey-2: #f2f2f4;
$grey-4: #d1e1f5;
$grey-6: #b3b3bf;

// Safari hack
$transparent-white: rgb(255 255 255 / 0%);

// Z-index globaux
$zi-modal: 100; // all modals
$zi-toaster: 101; // all toasters
