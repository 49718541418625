%heading-5 {
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.65;
}

@mixin heading-5() {
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.65;
}

%heading-4 {
  font-style: normal;
  font-weight: 500;
  font-size: 1.625rem;
  line-height: 1.38;
}

@mixin heading-4() {
  font-style: normal;
  font-weight: 500;
  font-size: 1.625rem;
  line-height: 1.38;
}

%heading-3 {
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 1.25;
}

@mixin heading-3() {
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 1.25;
}

%heading-2 {
  font-style: normal;
  font-weight: bold;
  font-size: 2.875rem;
  line-height: 1.22;
}

@mixin heading-2() {
  font-style: normal;
  font-weight: bold;
  font-size: 2.875rem;
  line-height: 1.22;
}

%heading-1 {
  font-style: normal;
  font-weight: bold;
  font-size: 3.5rem;
  line-height: 1.18;
}

@mixin heading-1() {
  font-style: normal;
  font-weight: bold;
  font-size: 3.5rem;
  line-height: 1.18;
}
