@use 'variables' as v;
@use 'breakpoints';
@use 'text';

.confirmation-saved {
  @extend %text-small-semibold;

  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;

  &__icon {
    color: $valid-green;
  }
}

.suggestion-form {
  .form-row {
    display: flex;
    margin: 0 -0.812rem;
    align-items: baseline;
    position: relative;

    &__button {
      color: v.$theme-primary;
      flex: 0 1 auto;
      margin: 0.812rem 0.812rem 0.812rem 0;
    }

    &__icon-button {
      color: v.$theme-primary;
      flex: 0 1 auto;
      min-width: 2rem;
      margin: 0.812rem 0.812rem 0.812rem 0;
    }

    &__error {
      right: 3.4rem;
    }
  }

  .input {
    margin: 0 0.812rem;

    &__full-width {
      flex: 1 1 auto;
    }

    &__large {
      flex: 1 1 auto;
    }

    &__small {
      flex: 0 1 auto;
    }
  }
}
