@import 'variables';

[class^='gdpr_overlay'] {
  transform: none !important;

  & > * {
    transform: none !important;
  }
}

[class^='gdpr_banner__cookie_icon'] {
  font-size: 1rem;
}

[class^='gdpr_banner__title'] {
  font-size: 1rem !important;
  font-weight: 600 !important;
  color: $theme-primary !important;
}

[class^='gdpr_banner__text'] {
  font-weight: 300 !important;
}

[class^='gdpr_switch__button'] {
  background: $theme-primary !important;
}

[class*='activated'] [class^='gdpr_switch__button'] {
  background-color: $theme-primary !important;
}
[class*='activated'] [class^='gdpr_switch__label'] {
  color: $theme-primary !important;
}

[class^='gdpr_banner__accept'] {
  color: $theme-primary !important;
  border-color: $theme-primary !important;

  @include handset {
    &:hover {
      background: $theme-primary !important;
      color: white !important;
    }
  }
}

[class^='gdpr_dialogbox__title'] {
  text-align: center;
  color: $theme-primary !important;
}
